// .nav-link {
//   color: black !important;
// }
// .c-navbar {
//   background: #ffffff;
//   box-shadow: 0px 3px 30px rgba(137, 137, 137, 0.144914);
//   font-family: $font-roboto;

//   padding: 0.1rem 1rem !important;

//   &__nav-item {
//     padding: 0 15px;
//     font-size: 14px;
//   }

//   .navbar-brand {
//     padding-top: 1rem;
//     padding-bottom: 1rem;
//     padding-right: 50px;
//   }

//   &-right {
//     align-items: center;
//     .col {
//       padding: 10px;
//     }
//     &__bag {
//       padding-right: 20px !important;
//       margin-right: 10px;
//       border-right: 1px solid #e1e1e1;
//     }
//     &__message {
//       font-size: 14px;
//       color: #676767;
//     }
//   }
// }

.c-navbar {
  background: #ffffff;
  box-shadow: 0px 3px 30px rgba(137, 137, 137, 0.144914);
  font-family: $font-roboto;

  padding: 0.1rem 1rem !important;

  &__nav-item {
    padding: 0 15px;
    font-size: 14px;
  }

  .navbar-brand {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 2.5rem;
    img {
      width: 110px;
    }
    @media only screen and (max-width: 991px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 0rem;
      img {
        width: 80px;
      }
    }
  }
}
