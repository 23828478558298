$payment-btn: #d42e20;
$apple-btn: #000000;
$primary-white: #ffffff;
$color-green: #34c37a;
$input-gray: #c3c6d1;

$brand-gray: #858997;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
$font-roboto: 'Roboto', serif;
